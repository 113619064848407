@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
 --mainBlue:#2a2a72;
 --lightBlue:#009ffd;
 --mainWhite:#f3f3f3;
 --mainDark:#232528;
 --mainYellow:#ffa400;
}
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box;
}
.bg-slate-900{
  background-color: #1F2937;
}
.bg-slate-800{
  background-color: #2D3748;
}
.themes{
  font-size:x-large;
}
.cardbg{
  background-color: #2D3748;
}
.paddinglr{
  width: 100%;
}
.h-full{
  height: 100vh;
}
.h-fit{
  height: fit-content;
}
.hover:hover{
  color: #009ffd;
}
.menubar{
  border:#979393 1px solid;
  padding-left: 3px; 
  padding-right: 3px;
  border-radius: 6px;
  font-size: x-large;
}
.bg-slate-200{
  background-color: #b1bac4;
}
.bg-grey-100{
  background-color: #ececec;
}
.resmenu{
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: flex-start;
}
.w-100{
  width: 100%;
}
.w-50{
  width: 50%;
}
.menu{
  display: flex;
  justify-content: flex-end;
}
.mainmenu{
  margin-left: 20px;
}
body {
  font-family: "PingFang SC", "Microsoft YaHei", "Noto Sans SC", "SimSun", sans-serif !important;
  color: var(--mainDark) !important;
  line-height: 1.6; /* Better readability for Chinese characters */
}
.text-title {
  font-family: "PingFang SC", "Microsoft YaHei", "Noto Sans SC", "SimSun", sans-serif !important;
  font-size: 1.2rem; /* Adjust for larger, legible Chinese text */
  letter-spacing: 0.05rem; /* Subtle spacing for a clean appearance */
}
.text-blue{
  color:var(--mainBlue);
}
.text-bright{
  color:var(--lightBlue);
}
.btn-black{
  background:transparent;
  text-transform:capitalize;
  font-size:0.8rem;
  color:var(--mainDark);
  border-radius: 0;
  border:0.1rem solid var(--mainDark);
}
.btn-black:hover{
  background:var(--mainDark) !important;
  color:var(--mainWhite) !important;
}
.cart-icon{
  cursor:pointer;
  font-size:1.2rem;
  color:var(--mainYellow);
}
.normal-tab-bar {
  display: flex;
  justify-content: center;
  padding: 0.6rem;
  background-color: #f8f9fa;
  border-bottom: 1px solid #ddd;
  margin-bottom: 0.5rem;
}

.tab-button {
  padding: 0.5rem 1rem;
  border: none;
  background-color: transparent;
  font-size: 0.65rem;
  cursor: pointer;
  transition: color 0.3s ease;
  color: #979393;
  font-weight: 400;
}

.tab-button.active {
  color: #b68e5b;
  font-weight: bold;
  border-bottom: 2px solid #b68e5b;
}


/* Main Layout */
.main-layout {
  display: flex;
  flex-direction: row;
  width: 100%; /* Ensure it fits the screen */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box;
  background-color: #f8f9fa;
}

/* Vertical Tabs */
.vertical-tabs {
  display: flex;
  flex-direction: column;
  width: 5.5rem;
  border-right: 1px solid #ddd;
}

.vertical-tab {
  all: unset; /* Resets all default styles */
  display: -webkit-box; /* Enables multi-line ellipsis */
  -webkit-box-orient: vertical; /* Required for line clamp */
  -webkit-line-clamp: 2; /* Limits text to two lines */
  overflow: hidden; /* Ensures excess text is hidden */
  text-overflow: ellipsis; /* Adds ellipsis for clipped text */
  font-size: 0.65rem; /* Apply desired font size */
  font-family: "PingFang SC", "Microsoft YaHei", "Noto Sans SC", sans-serif;
  font-weight:bold;
  cursor: pointer;
  padding: 10px; /* Add padding */
  text-align: left; /* Align text to the left */
  word-break: break-word; /* Prevent overflow of long words */
  transition: background-color 0.3s, color 0.3s;
  color: #979393 !important;
}


.vertical-tab:hover {
  background-color: #f0f0f0;
}

.vertical-tab.active {
  font-weight: bold;
  background-color:#b68e5b ;
  color: #fff !important;
  border-radius: 5px;
}

/* Ensure Vertical Tabs and Sticky Tab Bar Don't Overlap */
.tab-bar-container {
  z-index: 20;
}

/* Product List */
.product-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  z-index: 1; /* Lower priority so it doesn't block clicks */
  padding-left: 1rem;
}


.no-results {
  text-align: center;
  color: #4a5568; /* Modern, subtle gray */
  font-size: 1.2rem; /* Slightly larger font for better readability */
  font-family: "Inter", "Roboto", sans-serif; /* Modern sans-serif fonts */
  font-weight: 500; /* Medium weight for emphasis */
  padding: 20px;
  line-height: 1.6; /* Improved line spacing for readability */
  background-color: #f7fafc; /* Light background for emphasis */
  border-radius: 8px; /* Rounded corners for a modern look */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  max-width: 400px; /* Limit the width for better focus */
  margin: 20px auto; /* Center horizontally and add vertical spacing */
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85); /* Dark transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}

.modal-content {
  width: 90% !important; /* Force width to 80% */
  height: 90% !important; /* Force height to 80% */
  max-width: 500px; /* Optional: Set a max-width for larger screens */
  max-height: 90vh; /* Optional: Set a max-height for larger screens */
  background-color: rgba(34, 34, 34, 1) !important; /* Dark background for modal content */
  overflow-y: auto;
  border-radius: 20px !important; /* Rounded corners */
  border-width: 1px !important; /* Thickness of the border */
  border-style: solid !important; /* Ensures the border is visible */
  border-color: #ffed29 !important; /* Yellow color */
  text-align: left;
  color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}




.modal-content h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: white; /* White heading */
}

.modal-content p {
  margin-bottom: 1rem;
  font-size: 1rem;
  color: white; /* White text */
}

.modal-content button {
  background-color: #a99a3b
  ; /* Green button for confirmation */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.modal-content button:hover {
  background-color: #3e8e41; /* Darker green on hover */
}

.brand-name {
  font-family: "SimHei", "PingFang SC", sans-serif; /* Use 黑体 as the primary font */
  font-size: 2rem; /* Adjust font size for emphasis */
  text-transform: uppercase; /* Optional: Capitalize the brand name */
  color: #fff; /* Your brand color */
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
}



